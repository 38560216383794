import { Box, Card, CardContent, Typography } from '@mui/material';
import elements from '../data/elements';
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const AttributesCard = ({ attributes, isOnTheCusp = false }) => {
  if (!attributes) return null;

  const attributesList = [attributes.primary, attributes.secondary, attributes.relational].join('');    

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {isOnTheCusp 
              ? 'Cusp'
              : 'Attributes'
            }
          </Typography>
          <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
            {attributesList}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1.5, textTransform: 'uppercase' }}>
            Primary
          </Typography>
          <Typography variant="body2">
            {elements[attributes.primary].archetype} ({elements[attributes.primary].element})
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1.5, textTransform: 'uppercase' }}>
            Secondary
          </Typography>
          <Typography variant="body2">
            {elements[attributes.secondary].archetype} ({elements[attributes.secondary].element})
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1.5, textTransform: 'uppercase' }}>
            Relational
          </Typography>
          <Typography variant="body2">
            {elements[attributes.relational].archetype} ({elements[attributes.relational].element})
          </Typography>
          {isOnTheCusp &&
            <>
              <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2.5, textTransform: 'uppercase' }}>
                Details
              </Typography>
              <Typography variant="body2">
                Type: {attributes.type}
                <br />
                Cusp date: {dayjs(attributes.cuspDate, 'MMDDYYYY').format('MM/DD/YYYY')}
                <br />
                Days from cusp: {attributes.daysFromCusp}
              </Typography>          
            </>
          }
        </CardContent>
      </Card>
    </Box>    
  )
}

export default AttributesCard;
