const elementsData = {
  1: {
    number: 1,
    archetype: 'Philosopher',
    element: 'Water'
  },
  2: {
    number: 2,
    archetype: 'Caregiver',
    element: 'Earth'
  },
  3: {
    number: 3,
    archetype: 'Visionary',
    element: 'Wood'
  },
  4: {
    number: 4,
    archetype: 'Optimist',
    element: 'Wood'
  },
  5: {
    number: 5,
    archetype: 'Facilitator',
    element: 'Earth'
  },
  6: {
    number: 6,
    archetype: 'Protector',
    element: 'Metal'
  },
  7: {
    number: 7,
    archetype: 'Achiever',
    element: 'Metal'
  },
  8: {
    number: 8,
    archetype: 'Rock',
    element: 'Earth'
  },
  9: {
    number: 9,
    archetype: 'Networker',
    element: 'Fire'
  }
}

export default elementsData;
