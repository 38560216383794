import { Alert, AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import FormRow from './components/FormRow';
import VerifyAttributes from './components/VerifyAttributes';

function App() {
  const URL = process.env.REACT_APP_API_URL
  const [error, setError] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const rowTemplate = {
    firstname: '',
    lastname: '',
    group: '',
    subgroup: '',
    birthdate: null,
    attributes: null,
    isCheckedAssessment: true,
    assessmentUrl: '',
    isLoadingAttributes: false,
    isLoadingAssessment: false,
  }

  const [formRows, setFormRows] = useState([{ ...rowTemplate }])

  const allAssessmentUrls = () => {
    const allUrls = []
    formRows.forEach((formRow) => {
      if (formRow.assessmentUrl) {
        allUrls.push(formRow.assessmentUrl)
      }
    })
    return allUrls
  }

  const addFormRow = () => {
    setFormRows([ ...formRows, { ...rowTemplate } ])
  }

  const removeFormRow = () => {
    setFormRows(formRows.slice(0, -1))
  }

  const resetForm = () => {
    setFormRows([{ ...rowTemplate }])
    setIsSubmitted(false)
    setError(null)
  }

  const downloadAssessments = () => {
    const allUrls = allAssessmentUrls();
    axios.request({
      url: URL + "/api/zips",
      method: "POST",
      data: {
        files: allUrls
      }
    })
    .then((res) => {
      console.log(res.data);
      let url = res.data.zipFileUrl;
      url = url.replace("/Users/Shared/Code/five-elements/myfiveelements-services", URL);
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => {
      setError(e)
    })
  }

  const addToSpreadsheet = () => {
    axios.request({
      url: URL + "/api/sheets",
      method: "POST",
      data: [ ...formRows ]
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((e) => {
      setError(e)
    })
  }

  const addToDrive = () => {
    const allFileData = formRows.map(row => ({
      group: row.group,
      subgroup: row.subgroup,
      assessmentUrl: row.assessmentUrl,
    }))
    allFileData.forEach(fileData => {
      axios.request({
        url: URL + "/api/drive",
        method: "POST",
        data: fileData,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        setError(e)
      })  
    })
  }

  const handleInputChange = (index, fieldname, value) => {
    if (isSubmitted) {
      setIsSubmitted(false)
    }
    const updatedRows = [...formRows];
    updatedRows[index][fieldname] = value;
    setFormRows(updatedRows);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setError(null)
    const updatedRows = [...formRows];
    updatedRows.forEach((updatedRow) => {
      updatedRow.isLoadingAttributes = true;
      if (updatedRow.isCheckedAssessment) {
        updatedRow.isLoadingAssessment = true;
      }
    })
    setFormRows(updatedRows);
    
    formRows.forEach((formRow, index) => {
      const birthday = dayjs(formRow.birthdate).format('MMDDYYYY')
      
      axios.request({
        url: URL + "/api/attributes",
        method: "POST",
        data: { "birthday": birthday }
      })
      .then((res) => {
        const updatedRows = [...formRows];
        updatedRows[index].attributes = res.data;
        updatedRows[index].isLoadingAttributes = false;
        setFormRows(updatedRows);
      })
      .catch((e) => {
        console.error(e);
        if (e.response) {
          setError(e.response.data)
        } else if (e.request) {
          setError(e.request)
        } else {
          setError(e)
        }
        const updatedRows = [...formRows];
        updatedRows[index].isLoadingAttributes = false;
        setFormRows(updatedRows);
      })

      if (formRow.isCheckedAssessment) {
        axios.request({
          url: URL + "/api/assessment",
          method: "POST",
          data: { 
            "birthday": birthday,
            "firstname": formRow.firstname,
            "lastname": formRow.lastname,
          }
        })
        .then((res) => {
          const updatedRows = [...formRows];
          updatedRows[index].assessmentUrl = res.data.pdfFileUrl;
          updatedRows[index].isLoadingAssessment = false;
          setFormRows(updatedRows);
        })
        .catch((e) => {
          console.error(e);
          if (e.response) {
            setError(e.response.data)
          } else if (e.request) {
            setError(e.request)
          } else {
            setError(e)
          }
          const updatedRows = [...formRows];
          updatedRows[index].isLoadingAssessment = false;
          setFormRows(updatedRows);  
        })
      }
    
    })
    setIsSubmitted(true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" noWrap>
            My Five Elements Services
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Container sx={{ py: 6 }}>
        <VerifyAttributes />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            mt: 6,
            '& .MuiTextField-root': { mr: 2, ml: 0 },
          }}
        >
          <Typography variant="h4">
            Create assessment
          </Typography>
          <Grid container sx={{
            mt: 2, 
            "& .MuiBox-root": {
              borderBottom: 1, 
              borderColor: grey[300],
            },  
            "& .MuiBox-root:first-of-type": {
              borderTop: 1,
              borderColor: grey[300],
            }       
          }}>
          { formRows.map((formRow, index) => 
            <FormRow 
              formRow={formRow} 
              index={index} 
              isSubmitted={isSubmitted}
              handleInputChange={handleInputChange} 
              key={index}
            />
          )}
          </Grid>
          
          <Grid sx={{ mt: 3 }}>
            <Button 
              variant="contained" 
              type="submit" 
              sx={{ mr: 2 }} 
              disabled={isSubmitted}
            >
              Submit
            </Button>
            {isSubmitted && 
              <>
              <Button 
                variant="contained" 
                sx={{ mr: 2 }} 
                onClick={downloadAssessments} 
                disabled={!isSubmitted}
              >
                Download All
              </Button> 
              <Button 
                variant="contained" 
                sx={{ mr: 2 }} 
                onClick={addToDrive} 
                disabled={!isSubmitted}
              >
                Add to Drive
              </Button>
              <Button 
                variant="contained" 
                sx={{ mr: 2 }} 
                onClick={addToSpreadsheet} 
                disabled={!isSubmitted}
              >
                Add to Spreadsheet
              </Button>
              </>
            }
            <Button 
              variant="outlined" 
              size="small" 
              sx={{ mr: 1, ml: 1 }} 
              disableRipple 
              onClick={addFormRow}
            >
              Add Row
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              sx={{ mr: 1 }} 
              disableRipple 
              onClick={removeFormRow}
            >
              Remove Row
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              disableRipple 
              onClick={resetForm}
            >
              Reset
            </Button>
          </Grid>
        </Box>
        
        {error && 
          <Alert severity="error" sx={{ mt: 3 }}>
            {error?.message}
          </Alert>
        }
      </Container>
    </LocalizationProvider>
  );
}

export default App;
