import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import dayjs from 'dayjs';
import AttributesCard from './AttributesCard';

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const VerifyAttributes = () => {
  const [birthdate, setBirthdate] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleInputChange = (value) => {
    if (isSubmitted) {
      setIsSubmitted(false)
    }
    setBirthdate(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)    
    const birthday = dayjs(birthdate).format('MMDDYYYY')
    
    axios.request({
      url: process.env.REACT_APP_API_URL + "/api/attributes",
      method: "POST",
      data: { "birthday": birthday }
    })
    .then((res) => {
      setAttributes(res.data);
    })
    .catch((e) => {
      console.error(e);
      if (e.response) {
        setError(e.response.data)
      } else if (e.request) {
        setError(e.request)
      } else {
        setError(e)
      }
    })
    setIsLoading(false)
    setIsSubmitted(true)
  }

  const resetForm = () => {
    setBirthdate(null)
    setAttributes(null)
    setIsLoading(false)
    setIsSubmitted(false)
  }

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4">
        Verify attributes
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
        <DatePicker
          required
          name="birthdate"
          label="Birth date"
          autoComplete="off"
          value={birthdate}
          onChange={(e) => handleInputChange(e)}
          maxDate={dayjs()}
        />
        {isLoading &&
          <CircularProgress />
        }
      </Box>
      {attributes &&
        <Box sx={{ display: 'flex', alignItems: 'stretch', mt: 3, gap: 3 }}>
          <AttributesCard attributes={attributes} />
          <AttributesCard attributes={attributes.cuspAttributes} isOnTheCusp={true} />
        </Box>
      }
      <Grid sx={{ mt: 3 }}>
        <Button 
          variant="contained" 
          type="submit" 
          sx={{ mr: 2 }} 
          disabled={isSubmitted}
        >
          Submit
        </Button>
        <Button 
          variant="outlined" 
          size="small" 
          disableRipple 
          onClick={resetForm}
        >
          Reset
        </Button>
      </Grid>

      {error && 
        <Alert severity="error" sx={{ mt: 3 }}>
          {error?.message}
        </Alert>
      }
    </Box>
  );
}

export default VerifyAttributes;
