import { Box, Checkbox, Chip, CircularProgress, FormControlLabel, Link, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DatePicker } from '@mui/x-date-pickers';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from 'dayjs';

const FormRow = ({ formRow, index, isSubmitted, handleInputChange }) => {

  const AssessmentStatus = () => {
    if (formRow.isLoadingAssessment) {
      return <CircularProgress />
    } else if (isSubmitted) {
      return <AssessmentList />
    } else {
      return (
        <FormControlLabel
          control={
            <Checkbox 
              checked={formRow.isCheckedAssessment} 
              onChange={(e) => handleInputChange(index, "isCheckedAssessment", !formRow.isCheckedAssessment)}
            />
          }
          label="Create Assessment"
        />
      )
    }
  }

  const AttributesList = () => {
    if (!formRow.attributes) return null;

    let attributesList = [formRow.attributes.primary, formRow.attributes.secondary, formRow.attributes.relational].join('');    
    if (formRow.attributes.isOnTheCusp) {
      const cuspAttributesList = [formRow.attributes.cuspAttributes.primary, formRow.attributes.cuspAttributes.secondary, formRow.attributes.cuspAttributes.relational].join('');
      attributesList += " (Cusp: " + cuspAttributesList + ")"; 
    }

    return (
      <Chip label={attributesList} size="small" sx={{ ml: 2 }} />
    )
  }

  const AssessmentList = () => {
    if (!formRow.assessmentUrl) {
      return <ErrorIcon color="error" />
    }

    return (
      <>
        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
        <Link href={formRow.assessmentUrl} target="_blank" rel="noreferrer" download>
          Download
        </Link>
      </>
    )
  }
  
  return (
  <Box key={index} sx={{ display: 'flex', alignItems: 'center', py: 2 }}>
    <Grid xs={10} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" sx={{ mr: 1 }}>
        {index + 1}.
      </Typography>
      <TextField 
        name="firstname"
        label="First name"
        autoComplete="off"
        value={formRow.firstname}
        onChange={(e) => handleInputChange(index, "firstname", e.target.value)} 
      />
      <TextField 
        name="lastname" 
        label="Last name"
        autoComplete="off" 
        value={formRow.lastname}
        onChange={(e) => handleInputChange(index, "lastname", e.target.value)} 
      />
      <TextField 
        name="group" 
        label="Group"
        autoComplete="off" 
        value={formRow.group}
        onChange={(e) => handleInputChange(index, "group", e.target.value)} 
      />
      <TextField 
        name="subgroup" 
        label="Subgroup"
        autoComplete="off" 
        value={formRow.subgroup}
        onChange={(e) => handleInputChange(index, "subgroup", e.target.value)} 
      />
      <DatePicker
        required
        name="birthdate"
        label="Birth date"
        autoComplete="off"
        value={formRow.birthdate}
        onChange={(e) => handleInputChange(index, "birthdate", e)}
        maxDate={dayjs()}
      />
    </Grid>
    <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
      <AssessmentStatus />
      {formRow.isLoadingAttributes ? (     
        <CircularProgress />
      ) : (
        <AttributesList />
      )}
    </Grid>
  </Box>
  )
}

export default FormRow;
